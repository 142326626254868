<template>
  <v-card flat>
    <v-card-title>
      <h2 class="text-h5">
        Sales Market Share Analysis
      </h2>
    </v-card-title>
    <v-card-text>
      <div
        v-if="loaded"
        id="chart"
      >
        <div style="background-color: #F2F2F2; padding: 20px; border-radius: 5px; margin-bottom: 20px;">
          <p style="font-size: 16px; line-height: 1.5; margin-bottom: 10px;">
            {{ rawData[0].aiReport }}
          </p>
        </div>
        <apex-chart
          :height="height*.3"
          type="line"
          :options="lineChartOptions"
          :series="updateSeriesWithColors(lineChartSeries)"
        />
        <apex-chart
          :height="height*.3"
          type="area"
          :options="stackedAreaChartOptions"
          :series="updateSeriesWithColors(stackedAreaChartOptions.series)"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { useWindowSize } from '@vueuse/core'
import { apiService } from '@/api'
import { ref, onMounted, watch } from 'vue'
import { currencyConvert, updateSeriesWithColors } from '@/util/functions'
import { kFormatter } from '@core/utils/filter'
import regression from 'regression'

export default {
    name: 'SalesMarketShareAnalysis',
    setup() {
        const { width, height } = useWindowSize()
        const loaded = ref(false)
        const rawData = ref(null)
        const data = ref(null)
        const lineChartOptions = ref([])
        const lineChartSeries = ref([])
        const stackedAreaChartOptions = ref([])

        const getSalesMarketShareAnalysis = async () => {
            try {
                return await apiService.getSalesMarketShareAnalysis()
            } catch (error) {
                console.log(error)
            }
        }

        onMounted(() => {
            getSalesMarketShareAnalysis().then(
                res => {
                    rawData.value = res
                    data.value = res[0].chartData
                    loaded.value = true
                }
            )
        })

        watch(data, () => {
            if (data.value !== null) {
                // Transform the data into an array of objects
                const chartData = Object.keys(data.value.TAR).map(date => ({
                    date,
                    TAR: parseFloat(data.value.TAR[date]),
                    otherSales: parseFloat(data.value.otherSales[date]),
                }))

                console.log('🚀 ~ file: SalesMarketShareAnalysis.vue:80 ~ chartData ~ chartData:', chartData)

                // Calculate trend line using regression library
                const trendLineValues = regression.linear(chartData.map((datum, index) => [index, datum.TAR])).points.map(point => point[1])

                // Create the line chart options and series
                lineChartOptions.value = {
                    chart: {
                        group: 'SalesMarketShareAnalysis',
                        id: 'lineChart',
                        height: 400,
                        toolbar: {
                            show: true
                        }
                    },
                    forecastDataPoints: {
                        count: 0,
                        fillOpacity: 0.5,
                        strokeWidth: undefined,
                        dashArray: 4,
                    },
                    yaxis: {
                        labels: {
                            minWidth: 40,
                            formatter: value => currencyConvert(value),
                        }
                    },
                    xaxis: {
                        type: 'category',
                        categories: chartData.map(datum => datum.date),
                        tickAmount: 10
                    }
                }

                lineChartSeries.value = [
                    {
                        name: 'TAR',
                        data: chartData.map(datum => datum.TAR)
                    },
                    {
                        name: 'Other Generics',
                        data: chartData.map(datum => datum.otherSales)
                    },
                    {
                        name: 'Trend Line',
                        data: trendLineValues,
                        type: 'line'
                    }
                ]

                // Create the stacked area chart options and series
                stackedAreaChartOptions.value = {
                    chart: {
                        group: 'SalesMarketShareAnalysis',
                        id: 'stackedAreaChart',
                        height: 400,
                        stacked: true,
                        toolbar: {
                            show: true
                        }
                    },
                    forecastDataPoints: {
                        count: 0,
                        fillOpacity: 0.5,
                        strokeWidth: undefined,
                        dashArray: 4,
                    },

                    yaxis: {
                        labels: {
                            minWidth: 40,
                            formatter: value => currencyConvert(value),
                        }
                    },
                    xaxis: {
                        type: 'category',
                        categories: chartData.map(datum => datum.date),
                        tickAmount: 10
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        width: [1, 1, 4]
                    },
                    fill: {
                        opacity: [0.2, 0.2, 1]
                    },
                    colors: ['#8f5aeb'],
                    series: [
                        {
                            name: 'TAR',
                            data: chartData.map(datum => datum.TAR)
                        },
                        {
                            name: 'Other Generics',
                            data: chartData.map(datum => datum.otherSales)
                        },

                        // {
                        //     name: 'market share',
                        //     data: chartData.map(datum => (datum.TAR / (datum.TAR + datum.otherSales)) * 100)
                        // }
                    ],
                    labels: chartData.map(datum => datum.date)
                }
            }
        })

        return {
            updateSeriesWithColors,
            kFormatter,
            currencyConvert,
            getSalesMarketShareAnalysis,
            loaded,
            rawData,
            width,
            height,
            lineChartOptions,
            lineChartSeries,
            stackedAreaChartOptions,
        }
    }
}
</script>
